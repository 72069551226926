<template>
  <div class="no-data">
    <h1 class="text-xl font-medium">
      Oups, rien n'est encore là
    </h1>
    <p class="text-gray-500 max-w-md text-center">
      Nous sommes désolés. Il n'y a pas encore de publications. Veuillez revenir plus tard ou être le premier à partager vos pensées</p>
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>
  .no-data{
    @apply flex flex-col justify-center items-center flex-1;
  }
</style>