<template>
  <div class="create-post" @click="isModalOpen=true">
    <atom-image v-if="currentUser.avatar" 
    :src="imageUrl(currentUser.avatar)"
    class="create-post__avatar" variant="rounded">
    </atom-image>
    <span v-else class="create-post__avatar-placeholder">{{
      currentUser.firstname.slice(0, 1) +
      currentUser.lastname.slice(0, 1)
    }}</span>    <div class="create-post__content">
      <span class="create-post__text">Créer votre publication</span>
      <atom-icon class="create-post__icon" icon="add"></atom-icon>
    </div>
    <molecule-modal-wrapper :open="isModalOpen" @modalClose="isModalOpen=false">
      <block-create-or-update-post-form @modalClose="isModalOpen=false"></block-create-or-update-post-form>
    </molecule-modal-wrapper>
  </div>
</template>

<script setup>
import {ref, inject} from "vue";
import MoleculeModalWrapper from '@/components/molecules/MoleculeModalWrapper.vue';
import BlockCreateOrUpdatePostForm from '@/components/blocks/forms/BlockCreateOrUpdatePostForm.vue';
import {useAuth} from "@/composables/auth";

const {currentUser} = useAuth();
const isModalOpen = ref(false);
const imageUrl = inject('imageUrl');
</script>

<style lang="scss" scoped>
.create-post{
  @apply flex gap-2 items-center
  mb-4 py-2 px-4
  bg-white
  rounded-lg shadow-md
  cursor-pointer;
  &__avatar{
    @apply h-9 w-9;
    &-placeholder{
      @apply h-9 w-9 bg-primary text-white flex items-center justify-center p-4 rounded-full
      text-sm font-bold shadow-sm;
    }
  }
  &__text{
    @apply flex-1
    text-base text-gray-base;
  }
  &__content{
    @apply flex items-center flex-1
    bg-gray-100 pl-4 pr-[10px] py-1 rounded-full;
  }
  &__icon{
    @apply fill-[white] h-8 w-8 bg-primary rounded-full p-1;
  }
}
</style>