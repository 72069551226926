<template>
  <div :class="`image-upload ${active ? 'image-upload--active' : ''}`">
    <label class="image-upload__button" for="image-upload__input">
      <input
        type="file"
        hidden
        accept="image/*"
        id="image-upload__input"
        @change="updateSelectedImages"
        multiple
      />
      <atom-icon class="image-upload__icon" icon="image-add"></atom-icon>
      <span class="image-upload__text">Ajouter des photos</span>
    </label>
    <div v-show="selectedImages.length" class="image-upload__images">
      <div
        v-for="(image, index) in selectedImages.slice(0, 4)"
        class="image-upload__image"
        :key="index"
      >
        <div
          class="image-upload__image-overlay image-upload__image-overlay--light"
        ></div>
        <img :src="image" />
      </div>
      <div
        v-show="selectedImages.length > 4"
        class="image-upload__image"
      >
        <div class="image-upload__image-overlay">
          + {{ selectedImages.length - 4 }}
        </div>
        <img :src="selectedImages[4]" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onBeforeMount, toRefs } from "vue";
import {useStore} from "vuex";
import {filesMutationTypes} from "@/store/types/mutations";
const props = defineProps({
  active: {
    type: Boolean,
    default: false,
  },
});
const {active} = toRefs(props);
const {commit, state} = useStore();

onBeforeMount( () => {
  // Clear the store files 
  commit(`files/${filesMutationTypes.SET_FILES}`, []);
});
const files = computed(() => state.files.files);
const selectedImages = computed(() => {
  const selected = [];
  Array.prototype.forEach.call(files.value, (file) => {
    selected.push(URL.createObjectURL(file));
  });
  return selected;
});
const updateSelectedImages = (e) => {
  commit(`files/${filesMutationTypes.SET_FILES}`, e.target.files);
};
</script>

<style lang="scss" scoped>
.image-upload {
  @apply flex gap-3 items-center justify-between w-full py-2 bg-gray-50
  mt-2 px-2
  hidden rounded-md
  shadow-sm;
  &--active {
    @apply flex flex-col md:flex-row;
  }
  &__button {
    @apply flex-1 flex flex-col items-center gap-2 py-4
    bg-white cursor-pointer
    border border-dashed border-2 rounded-md;
  }
  &__text {
    @apply font-medium text-base;
  }
  &__icon {
    @apply w-10 h-10 bg-gray-50 rounded-full p-2;
  }
  &__images {
    @apply flex gap-2 justify-end;
  }
  &__image {
    @apply relative shadow-sm;
    img {
      @apply rounded-md h-32 w-32 object-cover;
    }
    &-overlay {
      @apply absolute bg-[rgba(0,0,0,.5)] h-full w-full rounded-md inset-0
      flex justify-center items-center text-white text-[30px] font-medium;
      &--light {
        @apply hidden bg-[rgba(0,0,0,.3)];
      }
    }
    &-icon {
      @apply hidden absolute top-[4px] right-[4px] fill-[white] cursor-pointer
      w-5 h-5;
    }
  }
}
</style>