<template>
  <form class="create-post__form">
    <div class="create-post__header">
      <h2 class="create-post__heading">{{isUpdate ? 'Modifier' : 'Ajouter'}} une publication</h2>
      <atom-icon
        @click="$emit('modalClose')"
        class="create-post__close"
        icon="close"
      ></atom-icon>
    </div>
    <div class="create-post__input-wrapper">
      <textarea
        placeholder="Créer votre publication"
        class="create-post__text"
        v-model="post.content"
        @blur="v$.content.$touch"
      ></textarea>
      <div class="create-post__errors">
        <span class="create-post__error" v-for="(err, index) in v$.content.$errors" :key="index">{{err.$message}}</span>
      </div>
      <component :is="mediaComponent" :active="isActive"></component>
    </div>
    <div v-if="!isUpdate" class="create-post__footer">
      <span class="create-post__footer-text">Ajouter à votre publication</span>
      <div class="create-post__media">
        <atom-icon
          class="create-post__media-icon"
          @click="showMediaComponent('image')"
          icon="image"
        ></atom-icon>
        <atom-icon
          class="create-post__media-icon"
          @click="showMediaComponent('file')"
          icon="attachment"
        ></atom-icon>
      </div>
    </div>
    <atom-button @click.prevent="createOrUpdatePost()" variant="primary lg"
      :class="`create-post__btn ${!v$.$invalid ? 'create-post__btn--active' : ''}`"
      >
      <svg :class="`create-post__btn-icon ${loading ? 'create-post__btn-icon--active' : ''}`" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
      {{isUpdate ? 'Modifier' : 'Publier'}}
    </atom-button
    >
  </form>
</template>

<script setup>
import { computed, ref, shallowRef } from "vue";
import { useVuelidate } from "@vuelidate/core";
import {required, helpers} from "@vuelidate/validators";
import { useStore } from "vuex";
const { state, dispatch, commit } = useStore();
const emit = defineEmits(["modalClose"]);
import MoleculeUploadImage from "@/components/molecules/MoleculeUploadImage.vue";
import MoleculeUploadFile from "@/components/molecules/MoleculeUploadFile.vue";
import { filesMutationTypes } from "@/store/types/mutations";
const props = defineProps({
  post:{
    type:Object,
    required: false
  }
});
const isUpdate = props.post ? true:false;
const post = ref({
  id: props.post?.id,
  content: props.post?.content,
  communityName: state.communities.currentCommunityName,
  files: props.post?.files ? props.post.files:[],
});
const rules = {
  content: {required:helpers.withMessage('Le contenu est requis', required)},
}
const v$ = useVuelidate(rules, post);
const mediaComponent = shallowRef(null);
const isActive = ref(false);
const loading = computed( () => state.posts.loading);
const showMediaComponent = (type) => {
  if (type == "image") {
    mediaComponent.value = MoleculeUploadImage;
    isActive.value = true;
  } else {
    mediaComponent.value = MoleculeUploadFile;
    isActive.value = true;
  }
};
const createOrUpdatePost = async () => {
  commit('posts/SET_LOADING',true);
  if(!isUpdate){
    try {
      // Upload the files if are any
      const filesToUpload = state.files.files;
      if (filesToUpload) {
        let uploadedFiles = await dispatch("files/upload", filesToUpload);
        uploadedFiles = uploadedFiles.map((file) => file.id);
        post.value = { ...post.value, files: uploadedFiles };
        commit(`files/${filesMutationTypes.SET_FILES}`, []);
      }
      // Create the post in the public community
      const createdPost = await dispatch("posts/createPost", post.value);
      commit('posts/SET_LOADING',false);
      emit("modalClose");
    } catch (err) {
      const { message } = err.response.data;
      console.log(message);
    }
  }
  else{
    try {
      const updatedPost = await dispatch("posts/updatePost", post.value);
      commit('posts/SET_LOADING',false);
      emit("modalClose");
    } catch (err) {
      const { message } = err.response.data;
      console.log(message); 
    }
  }
};
</script>

<style lang="scss" scoped>
.create-post {
  &__header {
    @apply w-full
      flex justify-between items-center
      pb-3;
  }
  &__close {
    @apply bg-gray-50 h-8 w-8 p-1 rounded-full fill-[gray] inline-block box-border cursor-pointer;
  }
  &__heading {
    @apply text-lg font-medium;
  }
  &__input,
  &__text {
    @apply py-2 px-3 w-full
      rounded-md resize-none;
  }
  &__input-wrapper {
    @apply relative;
  }
  &__text {
    @apply h-64;
  }
  &__form {
    @apply flex flex-col gap-4;
  }
  &__footer {
    @apply py-2 px-4 bg-gray-100
      flex items-center justify-between
      shadow-sm rounded-md;
    &-text {
      @apply font-medium;
    }
  }
  &__media {
    @apply flex gap-1;
    &-icon {
      @apply cursor-pointer;
    }
  }
  &__btn{
    @apply flex items-center justify-center bg-gray-200 pointer-events-none; 
    &--active{
      @apply bg-primary pointer-events-auto;
    }
    &-icon{
      @apply opacity-0 animate-spin -ml-1 mr-3 h-5 w-5 text-white;
      &--active{
        @apply opacity-100;
      }
    }
  }
  &__errors{
    @apply flex flex-col gap-1 mt-1 text-right;
  }
  &__error{
    @apply text-red-600 text-sm;
  }
}
</style>