<template>
  <template v-if="!loading">
    <component :is="postComponent"
      v-for="(post, index) in posts"
      :key="index"
      :post="post"
      @editPost="openEditModal($event)"
    />
    <molecule-no-data v-if="!posts?.length"></molecule-no-data>
  </template>
  <template v-else>
    <molecule-loading-post-card v-for="index in 10" :key="index"/>
  </template>
  <molecule-modal-wrapper :open="isModalOpen" @modalClose="isModalOpen=false">
    <block-create-or-update-post-form :post="editedPost" @modalClose="isModalOpen=false"></block-create-or-update-post-form>
  </molecule-modal-wrapper>
</template>

<script setup>
import { computed, toRefs, ref } from "vue";
import { useStore } from "vuex";
import MoleculeNoData from "@/components/molecules/MoleculeNoData.vue";
import MoleculePostCard from "@/components/molecules/cards/MoleculePostCard.vue";
import MoleculeRoomPostCard from "@/components/molecules/cards/MoleculeRoomPostCard.vue";
import MoleculeLoadingPostCard from "@/components/molecules/cards/MoleculeLoadingPostCard.vue";
import BlockCreateOrUpdatePostForm from "@/components/blocks/forms/BlockCreateOrUpdatePostForm.vue";
import MoleculeModalWrapper from "@/components/molecules/MoleculeModalWrapper.vue";

const { getters, state } = useStore();
const props = defineProps({
  communityName: {
    type: String,
    required: true,
  },
  isPrivate:{
    type:Boolean,
    default:false
  }
});
const isModalOpen = ref(false);
const { communityName, isPrivate } = toRefs(props);
const postComponent = isPrivate.value ? MoleculeRoomPostCard : MoleculePostCard;
const loading = computed(() => state.posts.loading);
const posts = computed(() =>
  getters["posts/getByCommunityName"](communityName.value)
);
const editedPost = ref(null);
const openEditModal = (post) => {
  isModalOpen.value = true;
  editedPost.value = post;
}
</script>

<style lang="scss" scoped>
</style>