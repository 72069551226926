<template>
  <div :class="`file-upload ${active ? 'file-upload--active' : ''}`">
    <label class="file-upload__button" for="file-upload__input">
      <input
        type="file"
        hidden
        id="file-upload__input"
        @change="updateSelectedFiles"
        multiple
      />
      <atom-icon 
        class="file-upload__icon"
        icon="attachment"></atom-icon>
      <span class="file-upload__text">Ajouter des fichiers</span>
    </label>
    <div v-show="files.length" class="file-upload__files">
      <div
        class="file-upload__file"
        v-for="(file, index) in Array.from(files).slice(0,3)"
        :key="index"
      >
        <atom-icon icon="file" class="file-upload__file-icon"></atom-icon>
        <span class="file-upload__file-name">{{file.name}}</span>
      </div>
      <div
        class="file-upload__file"
        v-show="files.length > 3"
      >
        <span class="file-upload__file-count"> + {{files.length - 3}}</span>
        <span class="file-upload__file-name">Other files</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, toRefs, onBeforeMount } from "vue";
import {useStore} from "vuex";
import {filesMutationTypes} from "@/store/types/mutations";
const {commit, state} = useStore();
const props = defineProps({
  active: {
    type: Boolean,
    default: false,
  },
});
const {active} = toRefs(props);
onBeforeMount( () => {
  // Clear the store files 
  commit(`files/${filesMutationTypes.SET_FILES}`, []);
});

// Get the files from store 
const files = computed(() => state.files.files );

// Save the selected files in store
const updateSelectedFiles = (e) => {
    commit(`files/${filesMutationTypes.SET_FILES}`, e.target.files);
};
</script>

<style lang="scss" scoped>
.file-upload {
  @apply flex gap-3 items-center justify-between w-full py-2 bg-gray-50
  mt-2 px-2
  hidden rounded-md
  shadow-sm;
  &--active {
    @apply flex;
  }
  &__button {
    @apply flex-1 flex flex-col items-center gap-2 py-4
    bg-white cursor-pointer
    border border-dashed border-2 rounded-md;
  }
  &__text {
    @apply font-medium text-base;
  }
  &__icon {
    @apply w-10 h-10 bg-gray-50 rounded-full p-2;
  }
  &__files {
    @apply flex gap-2 justify-end;
  }
  &__file{
    @apply flex-1 flex flex-col gap-4 justify-center items-center bg-white shadow-sm p-2
    border border-gray-50 rounded-sm h-32;
    &-name{
      @apply text-gray-base text-center;
    }
    &-icon{
      @apply h-6 w-6 fill-[primary];
    }
    &-count{
      @apply text-lg font-bold;
    }
  }
}
</style>