<template>
  <molecule-card-wrapper class="news">
    <atom-heading type="h2" variant="sm" class="news__heading">
      L'actualité financière marocaine
    </atom-heading>
    <div class="news__cards">
      <div class="news__cards-item" v-for="(article, index) in news?.slice(0,2)" :key="index">
        <div class="news__cards-item-header">
          <div class="news__cards-item-heading">
            <router-link :to="{name:'Article',params:{articleId:article.id}}" class="news__cards-item-title">
              {{article.title}}
            </router-link>
          </div>
          <span class="news__cards-item-date">
            {{ moment(article.createdAt).fromNow() }}
          </span>
        </div>
        <p class="news__cards-item-body">
          {{article.summary}}
        </p>
      </div>
    </div>
    <atom-button type="link" :to="{name:'News'}" variant="primary md full" class="news__button">
      Voir tout
    </atom-button>
  </molecule-card-wrapper>
</template>

<script setup>
import MoleculeCardWrapper from "./MoleculeCardWrapper.vue";
import moment from 'moment';
moment.locale('fr');
const props = defineProps({
  news:{
    type:Array,
    required:true
  }
});
</script>

<style lang="scss" scoped>
.news {
  &__cards {
    &-item {
      @apply py-4 px-3 shadow-md my-[22px];
      &-header {
        @apply flex justify-between mb-1;
      }
      &-heading {
      }
      &-title {
        @apply text-gray-900 text-sm lg:text-[20px] mb-0.5 font-medium
        max-w-[250px] lg:leading-6 inline-block;
      }
      &-brief {
        @apply text-sm text-gray-500;
      }
      &-date {
        @apply text-xs text-gray-500;
      }
      &-body {
        @apply text-sm text-gray-600;
      }
    }
  }
  &__button {
    @apply font-medium box-border;
  }
}
</style>
