<template>
  <molecule-card-wrapper class="room-post">
    <div class="room-post__header">
      <router-link :to="{ name: 'ForumPost', params: { postId: post.id } }" class="room-post__header-date"
        >Publié,
        {{ moment(post.createdAt).format("ddd MMM DD, YYYY") }}</router-link
      >
      <p v-if="showUser" class="room-post__header-author">
        Ajouté par
        <atom-link
          :to="{ name: 'ForumUserProfile', params: { userId: post.author.id } }"
          variant="light xs underline"
          >{{ post.author.firstname + " " + post.author.lastname }}</atom-link
        >
      </p>
    </div>
    <div class="room-post__body">
      <router-link
        :to="{ name: 'ForumPost', params: { postId: post.id } }"
        class="room-post__body-title"
      >
        {{ post.title }}
      </router-link>
      <p class="room-post__body-summary">
        {{ post.content }}
      </p>
      <div class="room-post__body-images">
        <Carousel v-if="post.files?.length" :settings="settings">
          <Slide v-for="(image, index) in post.files" :key="index">
            <img class="room-post__body-image" :src="imageUrl(image.path)" />
          </Slide>
          <template #addons="{ slidesCount }">
            <Navigation />
            <Pagination v-if="slidesCount > 1" />
          </template>
        </Carousel>
      </div>
    </div>
    <div class="room-post__footer">
      <div class="room-post__footer-item">
        <atom-icon
          class="room-post__footer-item-icon"
          icon="question-answer"
        ></atom-icon>
        <router-link
          class="room-post__footer-item-content"
          :to="{ name: 'ForumPost', params: { postId: post.id } }"
        >
          <span v-if="post.commentsCount == 0">Pas de commentaires</span>
          <span v-else
            >Commentaires
            <span class="text-primary font-bold">{{
              post.commentsCount
            }}</span></span
          >
        </router-link>
      </div>
      <div
        @click="reactToPost('like')"
        :class="`room-post__footer-item ${
          isCurrentUserReaction('like') ? 'room-post__footer-item--active' : ''
        }`"
      >
        <atom-icon
          class="room-post__footer-item-icon"
          icon="thumb-up"
          :fill="isCurrentUserReaction('like')"
        ></atom-icon>
        <span class="room-post__footer-item-content">{{
          post.reactions.likes
        }}</span>
      </div>
      <div
        @click="reactToPost('dislike')"
        :class="`room-post__footer-item ${
          isCurrentUserReaction('dislike')
            ? 'room-post__footer-item--active'
            : ''
        }`"
      >
        <atom-icon
          class="room-post__footer-item-icon"
          icon="thumb-down"
          :fill="isCurrentUserReaction('dislike')"
        ></atom-icon>
        <span class="room-post__footer-item-content">{{
          post.reactions.dislikes
        }}</span>
      </div>
    </div>
  </molecule-card-wrapper>
</template>

<script setup>
import AtomImage from "@/components/atoms/AtomImage.vue";
import AtomLink from "@/components/atoms/AtomLink.vue";
import AtomHeading from "@/components/atoms/AtomHeading.vue";
import AtomIcon from "@/components/atoms/AtomIcon.vue";
import MoleculeCardWrapper from "./MoleculeCardWrapper.vue";
import moment from "moment";
import "moment/locale/fr";
moment.locale("fr");
import { inject, ref, toRefs } from "vue";
import { useStore } from "vuex";
const { dispatch } = useStore();
const props = defineProps({
  showUser: {
    type: Boolean,
    default: true,
  },
  post: {
    type: Object,
    required: true,
  },
});
const { showUser, post } = toRefs(props);
const settings = ref({
  itemsToShow: 1,
});
const imageUrl = inject('imageUrl');
const isCurrentUserReaction = (type) => {
  return post.value.reactions.currentUserReaction == type;
};
const reactToPost = async (type) => {
  // Create reaction
  if (!isCurrentUserReaction(type)) {
    try {
      const isSuccess = await dispatch("posts/createReaction", {
        type,
        content: post.value.id,
      });
    } catch (err) {
      const { message } = err.response.data;
      console.log(message);
    }
  }
  // Remove it
  else {
    try {
      const isSuccess = await dispatch("posts/deleteReaction", {
        type,
        content: post.value.id,
      });
    } catch (err) {
      const { message } = err.response.data;
      console.log(message);
    }
  }
};
</script>

<style lang="scss">
.room-post {
  @apply bg-white;
  &__header {
    @apply flex justify-between;
    &-author {
      @apply text-xs text-[#D8D7D6] font-medium;
    }
    &-date{
      @apply text-xs text-black font-medium;
    }
  }
  &__body {
    &-title {
      @apply text-base leading-5 inline-block
      font-bold mt-2
      lg:text-lg;
    }
    &-summary {
      @apply text-sm text-black/80 mt-[13px] mb-4;
    }
    &-images {
      @apply flex flex-col gap-1 mb-6
      md:flex-row md:gap-[4px];
      .carousel {
        @apply flex-1;
      }
    }
    &-image {
      @apply rounded-md flex-1 md:w-0 h-[500px] object-cover;
    }
  }
  &__footer {
    @apply flex gap-13 mb-[3px];
    &-item {
      @apply flex items-center gap-1 cursor-pointer;
      &-icon {
        @apply fill-[#858585] w-4 h-4;
      }
      &-content {
        @apply text-[#858585] text-xs;
      }
      &--active {
        .room-post__footer-item {
          &-icon {
            @apply fill-[#7DAF8C];
          }
          &-content {
            @apply text-primary font-bold;
          }
        }
      }
    }
  }
  .carousel {
    &__icon {
      @apply fill-[black];
    }
    &__prev,
    &__next {
      @apply bg-gray-50 transition transition-all;
      &--in-active {
        @apply opacity-0;
      }
    }
    &__prev {
      @apply left-6;
    }
    &__next {
      @apply right-6;
    }
    &__pagination {
      @apply gap-2 relative my-1;
      &-button {
        @apply h-[12px] w-[12px] rounded-full;
        background-color: rgba(196, 196, 196, 0.6);
        &--active {
          @apply bg-primary;
        }
      }
    }
  }
}
</style>
